import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "../index.scss";
import { Form, Button, Select, Card, Typography, Radio, Space, Checkbox, notification } from "antd";
import FloatInput from "../../CommonWidgets/FloatInput";
import { createAccountRequest } from "../../../redux/actions/create-account";
import { updateLocalStorage, isFormInValid } from "../../../helper";
import stripeCountryList from "../../../config/stripeCountries";
import { LeftArrow, RightArrow } from "../../CommonWidgets/FontIcons";
import useAnalytics from "../../../hooks/useAnalytics";

const { Title, Text, Link } = Typography,
  { Option } = Select;

const SignUp = (props) => {
  const dispatch = useDispatch(),
    { trackRegister, trackRegisterFailed } = useAnalytics(),
    history = useHistory(),
    [currentStep, setStep] = useState(1),
    [form] = Form.useForm(),
    [value, setValue] = useState(1),
    [isLoading, setLoading] = useState(false),
    [hasAcceptedTerms, setHasAcceptedTerms] = useState(false),
    onCheckChange = (e) => {
      if (e.target.checked) {
        setHasAcceptedTerms(true);
      } else {
        setHasAcceptedTerms(false);
      }
    },
    onRadioChange = (e) => {
      setValue(e.target.value);
    },
    { createAccountData } = useSelector((state) => ({
      createAccountData: state.createAccountData,
    })),
    onRegister = () => {
      form
        .validateFields()
        .then(() => {
          if (!isFormInValid(form)) {
            hasSignUpFormValue.userName =
              value === 2 ? hasSignUpFormValue.tiktokHandle : hasSignUpFormValue.userName;
            hasSignUpFormValue.userType = value === 1 ? "musician" : "creator";
            delete hasSignUpFormValue.confirmPassword;
            setLoading(true);
            hasSignUpFormValue.email = hasSignUpFormValue.email.toLowerCase();

            dispatch(createAccountRequest(hasSignUpFormValue));
          } else {
            notification.error({
              message: "Error",
              description: "Please fix the errors",
              placement: "topRight",
            });
          }
        })
        .catch((info) => {
          notification.error({
            message: "Error",
            description: "Please fix the errors",
            placement: "topRight",
          });
        });
    },
    [hasSignUpFormValue, setSignUpFormValue] = useState({
      userName: "",
      email: "",
      inviteCode: "",
      country: "US",
      tiktokHandle: "",
      userPassword: "",
      confirmPassword: "",
    }),
    onChange = (e) => {
      let formData = { ...hasSignUpFormValue };
      if (e && e.target) {
        setSignUpFormValue({ ...formData, [e.target.name]: e.target.value });
      } else {
        setSignUpFormValue({ ...formData, country: e });
      }
    };

  useEffect(() => {
    setSignUpFormValue(hasSignUpFormValue);
  }, [hasSignUpFormValue]);

  useEffect(() => {
    if (!createAccountData.error && Object.keys(createAccountData?.payload).length) {
      let profileInfo = createAccountData?.payload?.data.userProfile;
      profileInfo.token = createAccountData?.payload?.data.token;
      trackRegister("email");
      updateLocalStorage(profileInfo);
      setLoading(false);
      window.rewardful("convert", { email: profileInfo?.email });
      if (profileInfo.userType === "creator") {
        window.location.href = "/offers";
      } else {
        // window.location.href = "/musician/creators";
        window.location.href = "/boost";
      }
    } else {
      if (createAccountData?.error?.message) {
        trackRegisterFailed("email", createAccountData?.error?.message);
        notification.error({
          message: "Error",
          description: createAccountData?.error?.message,
          placement: "topRight",
        });
        setLoading(false);
      }
    }
  }, [createAccountData]);

  useEffect(() => {}, []);

  return (
    <>
      <Title level={2} className="text-center">
        Sign Up
      </Title>
      <Form
        name="normal_login"
        initialValues={{
          remember: true,
        }}
        form={form}
        onFinish={onRegister}
      >
        <Space direction="horizontal" className="choose-type w-full">
          <span>I am a </span>
          <Radio.Group onChange={onRadioChange} value={value} size="small">
            <Radio value={1}>Musician</Radio>
            <Radio value={2}>Creator</Radio>
          </Radio.Group>
        </Space>

        {value === 2 && currentStep === 1 && (
          <>
            <Form.Item
              name={["user", "tiktokHandle"]}
              rules={[
                {
                  required: true,
                  message: "Please input your tiktok handle.",
                },
              ]}
              hasFeedback
            >
              <FloatInput
                required={true}
                size="large"
                label="Tiktok Handle"
                placeholder="Tiktok Handle"
                type="text"
                name="tiktokHandle"
                values={hasSignUpFormValue.tiktokHandle}
                onChange={(e) => onChange(e)}
              />
            </Form.Item>
            {/*} <Form.Item
                name={["user", "inviteCode"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please enter invite code",
                  },
                ]}
              >
                <FloatInput size="large" label="Invite Code" placeholder="Invite Code" type="text" name="inviteCode" values={hasSignUpFormValue.inviteCode} onChange={(e) => onChange(e)} />
              </Form.Item> */}
            <Form.Item
              name={["user", "country"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please select Country",
                },
              ]}
            >
              <Select defaultValue="US" name="country" size={"large"} onChange={(e) => onChange(e)}>
                {stripeCountryList &&
                  stripeCountryList.map((item) => {
                    return (
                      <Option key={item.code} value={item.code}>
                        {item.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button
                size="large"
                block
                onClick={() => setStep(2)}
                type={"primary"}
                disabled={
                  hasSignUpFormValue.tiktokHandle.length === 0 ||
                  !!form.getFieldsError().filter(({ errors }) => errors.length).length
                }
              >
                Next <RightArrow />
              </Button>
            </Form.Item>
          </>
        )}

        {value === 1 && (
          <Form.Item
            name={["user", "userName"]}
            rules={[
              {
                required: true,
                message: "Please input your name",
              },
            ]}
            hasFeedback
          >
            <FloatInput
              required={true}
              size="large"
              label="Name"
              placeholder="Name"
              className="w-full"
              type="text"
              name="userName"
              values={hasSignUpFormValue.userName}
              onChange={(e) => onChange(e)}
            />
          </Form.Item>
        )}
        {((value === 2 && currentStep === 2) || value === 1) && (
          <>
            <Form.Item
              name={["user", "email"]}
              rules={[
                {
                  type: "email",
                  message: "Please enter your email address",
                },
                {
                  required: true,
                  message: "Please input your Email",
                },
              ]}
              hasFeedback
            >
              <FloatInput
                required={true}
                size="large"
                label="Email"
                placeholder="Email"
                className="w-full"
                type="text"
                name="email"
                values={hasSignUpFormValue.email}
                onChange={(e) => onChange(e)}
              />
            </Form.Item>
            <Form.Item
              name="userPassword"
              rules={[
                {
                  required: true,
                  message: "Please input your password",
                },
                {
                  pattern: new RegExp(
                    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
                  ),
                  message:
                    "Minimum eight characters, at least one letter, one number and one special character:",
                },
              ]}
              hasFeedback
            >
              <FloatInput
                required={true}
                size="large"
                label="Password"
                placeholder="Password"
                type="password"
                name="userPassword"
                values={hasSignUpFormValue.userPassword}
                onChange={(e) => onChange(e)}
              />
            </Form.Item>
            <Form.Item
              name="confirm"
              // label="Confirm Password"
              dependencies={["userPassword"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("userPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The two passwords that you entered do not match")
                    );
                  },
                }),
              ]}
            >
              <FloatInput
                required={true}
                size="large"
                label="Confirm Password"
                placeholder="Confirm Password"
                type="password"
                name="confirmPassword"
                values={hasSignUpFormValue.confirmPassword}
                onChange={(e) => onChange(e)}
              />
            </Form.Item>
          </>
        )}
        {((value === 2 && currentStep === 2) || value === 1) && (
          <>
            <Form.Item className="mb3">
              <span className="terms">
                <Checkbox onChange={onCheckChange}> </Checkbox>
                <Link
                  underline
                  className="ml1"
                  href="https://pushtok.com/terms-of-use/"
                  rel="noreferrer"
                  target="_blank"
                >
                  I agree with the terms and conditions
                </Link>
              </span>
            </Form.Item>

            <Form.Item>
              <Button
                size="large"
                block
                type="primary"
                htmlType="button"
                loading={isLoading}
                disabled={
                  !hasAcceptedTerms ||
                  !form.isFieldsTouched(true) ||
                  !!form.getFieldsError().filter(({ errors }) => errors.length).length
                }
                onClick={() => onRegister()}
              >
                Create <RightArrow />
              </Button>
            </Form.Item>
          </>
        )}
        {value === 2 && currentStep === 2 && (
          <Form.Item>
            <Button size="large" type="default" onClick={() => setStep(1)}>
              <LeftArrow /> Back
            </Button>
          </Form.Item>
        )}
        <Form.Item className="text-center">
          <span>
            <Text>Already have an account?</Text>
            <Link underline className="ml1" onClick={() => props?.updateNewStep(1)}>
              Sign In
            </Link>
          </span>
        </Form.Item>
      </Form>
    </>
  );
};
export default SignUp;
